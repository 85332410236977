const PROPOSAL_INDEX = 3;
const DELIVERY_INDEX = 4;

const DAY_NAMES = ['月', '火', '水', '木', '金', '土', '日'];
const MONTH_NAMES = [
	'1月',
	'2月',
	'3月',
	'4月',
	'5月',
	'6月',
	'7月',
	'8月',
	'9月',
	'10月',
	'11月',
	'12月',
];

const EVENT_CATEGORIES = [
	{
		id: 1,
		title: 'キックオフミーティング',
		textColor: 'black',
		backgroundColor: '#FF9E7D',
		datePickerData: {
			dateRange: {
				start: '',
				end: '',
			},
		},
		scheduleEvent: 'estimate',
		isEdit: false,
		buttonEdit: false,
	},
	{
		id: 2,
		title: 'コンセプトデザイン',
		textColor: 'black',
		backgroundColor: '#FBB869',
		datePickerData: {
			dateRange: {
				start: '',
				end: '',
			},
		},
		scheduleEvent: 'decision',
		isEdit: false,
		buttonEdit: false,
	},
	{
		id: 3,
		title: 'クリエイティブ',
		textColor: 'black',
		backgroundColor: '#EDD267',
		datePickerData: {
			dateRange: {
				start: '',
				end: '',
			},
		},
		scheduleEvent: 'creative',
		isEdit: false,
		buttonEdit: true,
	},
	{
		id: 4,
		title: 'ご提案',
		textColor: 'black',
		backgroundColor: '#CBCA60',
		datePickerData: {
			dateRange: {
				start: '',
				end: '',
			},
		},
		scheduleEvent: 'proposal',
		isEdit: false,
		buttonEdit: true,
	},
	{
		id: 5,
		title: 'コンセプト仕上げ日',

		textColor: 'black',
		backgroundColor: '#73C99C',
		datePickerData: {
			dateRange: {
				start: '',
				end: '',
			},
			selectedDate: '',
		},
		scheduleEvent: 'delivery',
		isEdit: false,
		buttonEdit: false,
	},
];

const CALENDAR_EVENTS = [
	{
		categoryId: 1,
		title: 'キックオフミーティング',
		start: null,
		end: null,
	},
	{
		categoryId: 2,
		title: 'コンセプトデザイン',
		start: null,
		end: null,
	},
	{
		categoryId: 3,
		title: 'クリエイティブ',
		start: null,
		end: null,
	},
	{
		categoryId: 4,
		title: 'ご提案',
		start: null,
		end: null,
	},
	{
		categoryId: 5,
		title: 'コンセプト仕上げ日',
		start: null,
		end: null,
	},
];

const LAST_INPUT_ESTIMATE_IDX = 5;

export default {
	DAY_NAMES,
	MONTH_NAMES,
	EVENT_CATEGORIES,
	CALENDAR_EVENTS,
	PROPOSAL_INDEX,
	DELIVERY_INDEX,
	LAST_INPUT_ESTIMATE_IDX,
};
