import { mapState } from 'vuex';

const NEXT_MONTH_UNIT = 2;
const A_DAY_UNIT = 1;
const A_MONTH_UNIT = 1;
const A_INDEX_UNIT = 1;
const LAST_DAY_OF_MONTH_INDEX = 0;
const FIRST_WEEKDAY_OF_MONTH_INDEX = 1;
const FIRST_DAY_OF_WEEK_INDEX = 1;
const TOTAL_DAY_OF_WEEK = 7;
const DECEMBER_INDEX = 13;
const JANUARY_INDEX = 0;
const FIRST_DAY_NAME_INDEX = 2; // 1: Sunday, 2: Monday, etc
const SATURDAY_INDEX = 1;
const SUNDAY_INDEX = 7;
class Calendar {
	constructor(...args) {
		let date;
		if (args.length > 1) {
			let [year, month, ...rest] = args;
			date = new Date(year, month, ...rest);
		} else {
			date = new Date(...args);
		}
		date.setHours(0, 0, 0, 0);
		this._date = date;
	}

	getMonth() {
		return this._date.getMonth();
	}

	getPrevMonth() {
		return this._date.getMonth();
	}

	getNextMonth() {
		return this._date.getMonth() + NEXT_MONTH_UNIT;
	}

	getDay() {
		return this._date.getDay() + A_DAY_UNIT;
	}

	getMonthName(mode = 'long') {
		var monthName = this._date.toLocaleString('ja-JP', {
			month: mode,
		});
		const listWord = monthName.split('');
		listWord[listWord.length - A_INDEX_UNIT] = ' 月';
		monthName = listWord.join('');
		return monthName;
	}

	getFullYear() {
		return this._date.getFullYear();
	}

	getDate() {
		return this._date.getDate();
	}

	getTime() {
		return this._date.getTime();
	}

	getDayName(mode = 'long') {
		return this._date.toLocaleString('ja-JP', { weekday: mode });
	}

	getNextDay() {
		let day = new this.constructor(this.getTime());
		day.setDate(day.getDate() + A_DAY_UNIT);
		return day;
	}

	getPrevDay() {
		let day = new this.constructor(this.getTime());
		day.setDate(day.getDate() - A_DAY_UNIT);
		return day;
	}

	setDate(date) {
		this._date.setDate(date);
		return this.getTime();
	}

	isInRange(start, end) {
		let startDate = new this.constructor(start);
		let endDate = new this.constructor(end);
		let currentTime = this.getTime();
		let startCheck;
		let endCheck;
		startCheck = currentTime >= startDate.getTime();
		endCheck = currentTime <= endDate.getTime();
		return startCheck && endCheck;
	}

	toDateString() {
		return this._date.toDateString();
	}

	toISOString() {
		return this._date.toISOString();
	}

	getTimezoneOffset() {
		return this._date.getTimezoneOffset();
	}
	getNumberOfDaysInMonth() {
		return new this.constructor(
			this.getFullYear(),
			this.getMonth() + A_MONTH_UNIT,
			LAST_DAY_OF_MONTH_INDEX
		).getDate();
	}
	getNumberOfDaysInNextMonth() {
		return new this.constructor(
			this.getFullYear(),
			this.getMonth() + A_MONTH_UNIT * 2,
			LAST_DAY_OF_MONTH_INDEX
		).getDate();
	}

	getFirstWeekdayOfMonth() {
		return new this.constructor(
			this.getFullYear(),
			this.getMonth(),
			FIRST_WEEKDAY_OF_MONTH_INDEX
		).getDay();
	}
	getFirstWeekdayOfNextMonth() {
		if (this.getMonth() === DECEMBER_INDEX) {
			return new this.constructor(
				this.getFullYear() + 1,
				JANUARY_INDEX,
				FIRST_WEEKDAY_OF_MONTH_INDEX
			).getDay();
		}
		return new this.constructor(
			this.getFullYear(),
			this.getMonth() + 1,
			FIRST_WEEKDAY_OF_MONTH_INDEX
		).getDay();
	}
}

export default {
	name: 'Calendar',
	data() {
		return {
			today: new Calendar(),
			date: null,
			weekdays: null,
		};
	},
	computed: {
		days() {
			let emptyDays = Array(
				(this.startWeekDayOfMonth - this.firstDayOfWeek + TOTAL_DAY_OF_WEEK) %
					TOTAL_DAY_OF_WEEK
			).fill(null);
			let days = Array(this.numberOfDays)
				.fill()
				.map(
					(item, index) =>
						new Calendar(this.selectedYear, this.selectedMonth, index + A_INDEX_UNIT)
				);
			return emptyDays.concat(days);
		},
		nextMonthdays() {
			let emptyDays = Array(
				(this.startWeekDayOfNextMonth - this.firstDayOfWeek + TOTAL_DAY_OF_WEEK) %
					TOTAL_DAY_OF_WEEK
			).fill(null);
			let days = Array(this.numberOfDaysNextMonth)
				.fill()
				.map(
					(item, index) =>
						new Calendar(
							this.selectedYear,
							this.selectedMonth + 1,
							index + A_INDEX_UNIT
						)
				);
			return emptyDays.concat(days);
		},
		startWeekDayOfMonth() {
			return this.date.getFirstWeekdayOfMonth();
		},
		startWeekDayOfNextMonth() {
			return this.date.getFirstWeekdayOfNextMonth();
		},
		numberOfDays() {
			return this.date.getNumberOfDaysInMonth();
		},
		numberOfDaysNextMonth() {
			return this.date.getNumberOfDaysInNextMonth();
		},
		selectedMonth() {
			return this.date.getMonth();
		},
		selectedNextMonth() {
			return this.date.getNextMonth();
		},
		selectedMonthName() {
			return this.date.getMonthName();
		},
		prevMonthName() {
			if (this.date.getPrevMonth() === JANUARY_INDEX) {
				return '12月';
			} else {
				return this.date.getPrevMonth() + ' 月';
			}
		},
		nextMonthName() {
			if (this.date.getNextMonth() === DECEMBER_INDEX) {
				return '1月';
			} else {
				return this.date.getNextMonth() + ' 月';
			}
		},
		selectedYear() {
			return this.date.getFullYear();
		},
		selectYearNextMonth() {
			return this.date.getFullYearNextMonth();
		},
		calendarYear() {
			return this.date.getFullYear() + ' 年';
		},
		...mapState(['preview']),
	},
	methods: {
		prevMonth() {
			if (this.preview) return;
			this.date = new Calendar(
				this.selectedYear,
				this.selectedMonth - A_MONTH_UNIT,
				A_MONTH_UNIT
			);
		},
		nextMonth() {
			if (this.preview) return;
			this.date = new Calendar(
				this.selectedYear,
				this.selectedMonth + A_MONTH_UNIT,
				A_MONTH_UNIT
			);
		},
		generateWeekdayNames(firstDayOfWeek = FIRST_DAY_OF_WEEK_INDEX) {
			let weekdays = ['日', '月', '火', '水', '木', '金', '土'];
			for (let i = 2; i <= firstDayOfWeek; i++) {
				let first = weekdays.shift();
				weekdays.push(first);
			}
			return weekdays;
		},
		generateDayStyle(date) {
			let style = {};
			for (let event of this.events) {
				if (date.isInRange(event.start, event.end)) {
					let category =
						this.eventCategories.find((item) => item.id === event.categoryId) || {};
					Object.assign(style, {
						color: category.id ? category.textColor : null,
						backgroundColor: category.id ? category.backgroundColor : null,
					});
				}
			}
			return style;
		},
		generateBeforeStyle(date) {
			let style = {};
			for (let event of this.events) {
				if (
					date.isInRange(event.start, event.end) &&
					date.getPrevDay().isInRange(event.start, event.end)
				) {
					let category =
						this.eventCategories.find((item) => item.id === event.categoryId) || {};
					Object.assign(style, {
						backgroundColor: category.backgroundColor,
					});
				}
			}
			return style;
		},
		generateAfterStyle(date) {
			let style = {};
			for (let event of this.events) {
				if (
					date.isInRange(event.start, event.end) &&
					date.getNextDay().isInRange(event.start, event.end)
				) {
					let category =
						this.eventCategories.find((item) => item.id === event.categoryId) || {};
					Object.assign(style, {
						backgroundColor: category.backgroundColor,
					});
				}
			}
			return style;
		},
		goToday() {
			this.date = this.today;
		},
	},
	props: {
		initialDate: {
			type: String,
			default: null,
		},
		firstDayOfWeek: {
			type: Number,
			default: FIRST_DAY_NAME_INDEX, // 1: Sunday, 2: Monday, etc
		},
		eventCategories: {
			type: Array,
			default() {
				return [];
			},
		},
		events: {
			type: Array,
			default() {
				return [];
			},
		},
		offDays: {
			type: Array,
			default() {
				return [SATURDAY_INDEX, SUNDAY_INDEX];
			},
		},
		verticalCalendar: {
			type: Boolean,
			default: false,
		},
	},
	beforeMount() {
		this.date = Date.parse(this.initialDate)
			? new Calendar(this.initialDate)
			: new Calendar();
		this.weekdays = this.generateWeekdayNames(this.firstDayOfWeek);
	},
};
